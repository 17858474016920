<template>
  <div class="map-display">
    <map-draw ref="mapDraw" :map="map" @mode-complete="modeComplete"></map-draw>
    <div id="map" class="map" />
    <map-controls-drawer
      :map="map"
      :draw="draw"
      :geolocate="geolocate"
      :selectedFeature="selectedFeature"
      @delete-point="deletePoint"
      ref="controlsDrawer"
    />
    <map-layer-controls :map="map" />
    <div class="d-none">
      <div class="content-menu" ref="newTaskIncidentMenu">
        <div class="content-row">
          <button class="btn-plain" @click="createNewItem('new-task')">
            New Note
          </button>
        </div>
        <div class="content-row">
          <button class="btn-plain" @click="createNewItem('new-incident')">
            New Incident
          </button>
        </div>
        <div class="content-row">
          <button class="btn-plain" @click="createNewItem('new-asset')">
            New Asset
          </button>
        </div>
      </div>
    </div>
    <map-context-popup
      :map="map"
      :draw="draw"
      ref="contextPopup"
      @delete-point="deletePoint"
      @edit-point="editPoint"
    />
  </div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import Vue from "vue";
import MapControlsDrawer from "@/components/map/MapControlsDrawer";
import MapLayerControls from "@/components/map/MapLayerControls";
import MapContextPopup from "@/components/map/MapContextPopup";
import MapDraw from "@/components/map/MapDraw";
import BasePopup from "./popups/BasePopup.vue";

export default {
  name: "MapDisplay",
  components: {
    MapControlsDrawer,
    MapLayerControls,
    MapContextPopup,
    MapDraw,
    BasePopup,
  },
  props: {
    mapFeatures: {
      type: Object,
      required: true,
    },
    mapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: null,
      loaded: false,
      draw: null,
      geolocate: null,
      selectedFeature: null,
      confirmedDelete: false,
      showDeleteConfirmation: false,
      //pointOptionMenu: null,
      newTaskIncidentMenu: null,
      fTouch: false,
    };
  },

  computed: {
    deleteText() {
      return this.showDeleteConfirmation ? "Really Delete?" : "Delete";
    },
    mapOptions() {
      return {
        container: "map",
        currentLatLong: null,
        loaded: false,
        style: this.mapData.style,
        map: this.map,

        displayedControls: {
          point: false,
          line_string: false,
          polygon: false,
          trash: false,
          combine_features: false,
          uncombine_features: false,
        },
      };
    },
  },
  watch: {
    mapFeatures() {
      if (this.mapFeatures) {
        this.loadFeatures();
      }
    },
  },
  mounted() {
    if (!this.loaded) {
      this.init();
    }
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
      this.currentLatLong = null;
    }
  },
  methods: {
    init() {
      this.fTouch = this.isTouchDevice();

      mapboxgl.accessToken = this.mapData.accessToken;

      this.map = new mapboxgl.Map({
        trackResize: true,
        attributionControl: false,
        container: "map",
        style: this.mapData.style,
      });

      this.map.on("load", () => {
        this.loaded = true;

        this.draw = this.$refs.mapDraw.init();

        const mapControls = this.loadMapControls();

        this.geolocate = mapControls.geolocate;

        this.loadFeatures();

        this.initResizeObserver();

        this.addMapListeners();
      });
    },

    isTouchDevice() {
      let fTouch =
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0;

      console.log(`This ${fTouch ? "IS" : "IS NOT"} a touch device`);

      return fTouch;
    },

    showContentMenuPopup(e) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["gl-draw-point-inactive.cold"],
      });

      if (features && features.length != 0) return;

      //if (e.mode != "simple_select" && e.mode != "direct_select") return

      this.currentLatLong = e.lngLat;

      this.newTaskIncidentMenu = new mapboxgl.Popup({ className: "new-popup" })
        .setLngLat(e.lngLat)
        .setDOMContent(this.$refs.newTaskIncidentMenu)
        .addTo(this.map);
    },

    /*
    showPointOptionMenuPopup(coordinates) {
      this.pointOptionMenu = new mapboxgl.Popup({ className: "new-popup" })
        .setLngLat(coordinates)
        .setHTML('<div id="optionmenu-popup-content"></div>')
        .addTo(this.map);
      const OptionMenuClass = Vue.extend(BasePopup);
      const popupInstance = new OptionMenuClass({});
      popupInstance.$mount("#optionmenu-popup-content");
      popupInstance.$on("confirmDelete", () => {
        this.deletePoint();
      });
    },
    */

    createNewItem(name) {
      this.newTaskIncidentMenu.remove();

      let query = { ...this.$route.query };
      query.when = `${Date.now()}`;

      this.$router.push({
        name,
        params: { latLong: this.currentLatLong },
        query: query,
      });
    },

    modeComplete() {
      this.$refs.controlsDrawer.setActiveMode("none");

      this.$refs.controlsDrawer.clearDrawMode();
      this.$refs.controlsDrawer.setDrawMode("simple_select");
    },

    addMapListeners() {
      // When second parameter, layerId is provided, contextmenu event only triggers when
      // right-clicking an object on the map.

      /*
      this.map.on("contextmenu", "gl-draw-point-inactive.cold", (e) => {
        this.currentLatLong = e.lngLat;
        this.showPointOptionMenuPopup(e.lngLat);
      });
      */

      // When second parameter, layerId is absent, contextmenu event will trigger regardless
      // of whether or not an object was right-clicked.

      this.map.on("contextmenu", (e) => {
        this.showContentMenuPopup(e);
      });

      this.map.on("touchstart", (e) => {
        // Only consider displaying an informative popup if we are not in
        // one of the object creation modes (distance, area, note, etc.)

        this.$refs.controlsDrawer.consoleModes("Touch start");

        let activeMode = this.$refs.controlsDrawer.getActiveMode();

        if (activeMode == "none") this.$refs.contextPopup.touchPopup(e);

        if (activeMode == "task" || activeMode == "incident") {
          let routeName = `new-${activeMode}`;

          this.$router.push({
            name: routeName,
            params: { latLong: e.lngLat },
            query: { ...this.$route.query },
          });

          this.modeComplete();
        }
      });

      this.map.on("touchend", (e) => {
        this.$refs.controlsDrawer.consoleModes("Touch end");
      });

      this.map.on("touchmove", (e) => {
        this.$refs.controlsDrawer.consoleModes("Touch move");
      });

      this.map.on("touchcancel", (e) => {
        this.$refs.controlsDrawer.consoleModes("Touch cancel");
      });

      this.map.on("mousedown", (e) => {
        this.$refs.controlsDrawer.consoleModes("Mouse down");
      });

      this.map.on("mouseup", (e) => {
        this.$refs.controlsDrawer.consoleModes("Mouse up");
      });

      this.map.on("mouseover", (e) => {
        this.$refs.controlsDrawer.consoleModes("Mouse over");
      });

      this.map.on("click", (e) => {
        this.$refs.controlsDrawer.consoleModes("Click");
      });

      this.map.on("mousemove", (e) => {
        if (this.fTouch) {
          this.$refs.controlsDrawer.consoleModes("Mouse move on touch device");
        }

        this.$refs.contextPopup.mapMouseMove(e);
      });

      this.map.on("draw.modechange", (e) => {
        if (e.mode == "simple_select" || e.mode == "direct_select") {
          this.$refs.controlsDrawer.setActiveMode("none");
        }
        this.$refs.controlsDrawer.clearDrawMode();
        this.$refs.controlsDrawer.setDrawMode(e.mode);
      });

      this.map.on("draw.update", (e) => {});

      this.map.on("draw.selectionchange", (e) => {
        /*
        This code is disabled to prevent clicking/selecting an object
        from raising the sidebar with editing for that object.

        if (e.features.length == 0) return;

        const { id, routeName } = e.features[0].properties;

        if (e && id) {
          this.$router.push({
            name: routeName,
            params: { id },
            query: { ...this.$router.currentRoute.query },
          });
        }
        */
      });

      this.map.on("draw.selectionchange", (e) => {
        this.selectedFeature = e.features[0];
      });
    },

    initResizeObserver() {
      // The Resize Observer ensures that the map is properly
      // re-displayed when the size of the window changes, for example
      // when the sidebar is hidden or displayed

      const resizeObserver = new ResizeObserver((entries) => {
        this.map.resize();
      });

      resizeObserver.observe(document.querySelector("div#map"));
    },

    loadMapControls() {
      var attribution = new mapboxgl.AttributionControl({ compact: false });
      this.map.addControl(attribution);

      var scale = new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: "imperial",
      });
      this.map.addControl(scale, "bottom-right");

      var nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, "bottom-right");

      // Initialize the geolocate control.
      var geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });

      this.map.addControl(geolocate);

      geolocate.on("geolocate", function (data) {
        console.log("A geolocate event has occurred.", data);
      });

      return {
        geolocate: geolocate,
      };
    },

    loadFeatures() {
      if (
        this.mapFeatures &&
        typeof this.mapFeatures.type != undefined &&
        this.mapFeatures.type == "FeatureCollection"
      ) {
        this.draw.set(this.mapFeatures);
      }
    },

    mutateFeature(featureEdit) {
      // This is a hack and a potential symptom of a problem.
      // For some reason featureEdit does not have the 'user_' properties
      // we expect in map features that represent Skyfall entities

      if (!featureEdit.properties.hasOwnProperty("user_id")) {
        featureEdit.properties.user_id = featureEdit.properties.id;
      }

      if (!featureEdit.properties.hasOwnProperty("user_routeName")) {
        featureEdit.properties.user_routeName =
          featureEdit.properties.routeName;
      }

      if (!featureEdit.properties.hasOwnProperty("user_type")) {
        featureEdit.properties.user_type = featureEdit.properties.type;
      }

      if (!featureEdit.properties.hasOwnProperty("id")) {
        featureEdit.properties.id = featureEdit.id;
      }

      return featureEdit;
    },

    editPoint(featureEdit) {
      if (featureEdit == null) return;

      let localFeatureEdit = this.mutateFeature(featureEdit);

      // Measurements are just Mapbox objects and consequently cannot
      // be edited.  This will change when we make measurements persistent.

      if (
        localFeatureEdit.properties.user_type == "Area" ||
        localFeatureEdit.properties.user_type == "Measurement"
      ) {
        this.draw.changeMode("direct_select", {
          featureId: localFeatureEdit.properties.id,
        });

        this.$refs.contextPopup.hidePopup();
        return;
      }

      const { user_id, user_routeName } = localFeatureEdit.properties;

      if (user_id) {
        let query = { ...this.$router.currentRoute.query };
        query.when = `${Date.now()}`;

        this.$router.push({
          name: user_routeName,
          params: { id: user_id },
          query: query,
        });

        this.$refs.contextPopup.hidePopup();
      }
    },

    deletePoint(featureDelete) {
      if (featureDelete == null) return;

      let localFeatureDelete = this.mutateFeature(featureDelete);

      // Measurements are just Mapbox objects and they are simply
      // deleted in Mapbox.  This will change when we make measurements persistent.

      if (
        localFeatureDelete.properties.user_type == "Area" ||
        localFeatureDelete.properties.user_type == "Measurement"
      ) {
        this.$refs.contextPopup.hidePopup();
        this.draw.delete([localFeatureDelete.properties.id]);
        return;
      }

      const { user_id, user_routeName } = localFeatureDelete.properties;
      this.confirmedDelete = true;

      const storeModule = {
        "task-detail": "tasks",
        "incident-detail": "incidents",
        "asset-detail": "assets",
      }[user_routeName];

      let query = { ...this.$router.currentRoute.query };
      query.when = `${Date.now()}`;

      let path = this.$router.currentRoute.path;

      this.$store.dispatch(`${storeModule}/deleteById`, user_id).then(() => {
        this.$refs.contextPopup.hidePopup();

        // If we were viewing the detail page for the entity which is
        // being deleted, navigate up to the parent, list of these entities

        if (path == `/${storeModule}/${user_id}`) {
          this.$router.push({
            name: storeModule,
            query: query,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.map-display {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
}
.map {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
}
.new-popup {
  .mapboxgl-popup-tip {
    display: none;
  }
  .content-menu {
    width: 125px;
    text-align: left;
    margin: 3px;
  }
  .content-row {
    padding: 0px 5px;
    button:focus {
      outline: none;
    }
    button {
      width: 100%;
      font-size: 14px;
      text-align: left;
    }
    &:hover {
      cursor: pointer;
      background-color: #f5f5f5;
    }
    .delete {
      color: rgb(255, 84, 0);
    }
  }
  .mapboxgl-popup-content {
    margin-top: 5px;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }
}
button.mapboxgl-ctrl-geolocate {
  display: none;
}
</style>
