<template>
  <div
    class="img-wrapper-relative control-wrapper"
    title="Add Note"
    @click="goTaskMode"
  >
    <img
      v-if="this.$parent.checkActiveMode('task')"
      class="
        c-d-default-none
        img-comment img-hidden img-comment-selected
        control_selection
      "
      alt="not-selected"
      src="@/assets/img/app/highlight2-dark.svg"
    />
    <img
      v-if="this.$parent.checkActiveMode('task')"
      class="
        c-d-dark-none
        img-comment img-hidden img-comment-selected
        control_selection
      "
      alt="not-selected"
      src="@/assets/img/app/highlight2.svg"
    />
    <img
      id="img-comment-dark"
      class="c-d-default-none img-comment"
      alt="not-selected"
      src="@/assets/img/app/add-comment-dark.svg"
    />
    <img
      id="img-comment-light"
      class="c-d-dark-none img-comment"
      alt="not-selected"
      src="@/assets/img/app/add-comment-light.svg"
    />
  </div>
</template>

<script>
export default {
  name: "MapTaskControl",
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    goTaskMode() {
      this.$emit("clear-draw-mode");
      this.$emit("set-draw-mode", "comment");
      this.draw.changeMode("comment");
      this.$emit("set-active-mode", "task");
    },
  },
};
</script>

<style scoped>
.control-wrapper {
  width: 46px;
  height: 46px;
  opacity: 1;
  padding: 0px;
  position: relative;
  cursor: pointer;
}
.control-wrapper img {
  width: 46px;
  height: 46px;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 100;
  padding: 6px 2px;
}
img.control_selection {
  padding: 0px;
}
</style>
