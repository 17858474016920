<template>
  <div
    class="img-wrapper-relative control-wrapper"
    title="Area Measurement"
    @click="goAreaMode"
  >
    <img
      v-if="this.$parent.checkActiveMode('area')"
      class="
        c-d-default-none
        img-draw_line_string
        img-hidden
        img-draw_line_string-selected
        control_selection
      "
      alt="not-selected"
      src="@/assets/img/app/highlight2-dark.svg"
    />
    <!-- highlight -->
    <img
      v-if="this.$parent.checkActiveMode('area')"
      class="
        c-d-dark-none
        img-draw_line_string
        img-hidden
        img-draw_line_string-selected
        control_selection
      "
      alt="not-selected"
      src="@/assets/img/app/highlight2.svg"
    />
    <img
      id="img-draw_line_string-dark"
      class="c-d-dark-none img-draw_line_string"
      alt="not-selected"
      src="@/assets/img/app/draw-polygon-light.svg"
    />
    <img
      id="img-draw_line_string-light"
      class="c-d-default-none img-draw_line_string"
      alt="not-selected"
      src="@/assets/img/app/draw-polygon-dark.svg"
    />
  </div>
</template>

<script>
export default {
  name: "MapAreaControl",
  props: {
    map: {
      type: Object,
      default: null,
    },
    draw: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    goAreaMode() {
      this.$emit("clear-draw-mode");
      this.$emit("set-draw-mode", "draw_polygon");
      this.draw.changeMode("draw_polygon");
      this.$emit("set-active-mode", "area");
    },
  },
};
</script>

<style scoped>
.control-wrapper {
  width: 46px;
  height: 46px;
  opacity: 1;
  padding: 0px;
  position: relative;
  cursor: pointer;
}
.control-wrapper img {
  width: 46px;
  height: 46px;
  opacity: 1;
  display: block;
  position: absolute;
  z-index: 100;
  padding: 6px 2px;
}
img.control_selection {
  padding: 0px;
}
</style>
